import React from 'react';
import { FormattedMessage } from 'react-intl';

import { JobTypeMessages } from 'src/common/messages/jobs';
import { JobType } from 'src/global/models/Job';
import { StepNames } from 'src/modules/OnboardingGuided/constants';

import { CountryCodes } from './enums';

export const EA_LICENCE_NUMBER = '20C0196';
export const VN_BUSINESS_REGISTRATION_NUMBER = '0316168834';

export const MAX_JOB_ROLES = 5;
export const MAX_WORK_LOCATIONS = 3;

export const MIN_AGE = 18;

export const SIMILAR_JOBS_LIMIT = 3;

export const POPOVER_OPTION_HEIGHT = 40;
export const POPOVER_OPTION_MARGIN_TOP = 1;

export const JOB_TITLE_SUGGESTION_LIMIT = 5;

export const MAX_ABOUT_ME_LENGTH = 2600;

export const MAX_TEXTAREA_LENGTH = 2000;

export const POPULAR_CITIES = [
  {
    name: 'Jakarta',
    label: 'Jakarta',
    id: 28904,
    value: 28904,
    CountryCode: 'ID',
  },
  {
    name: 'Kuala Lumpur',
    label: 'Kuala Lumpur',
    id: 122752,
    value: 122752,
    CountryCode: 'MY',
  },
  {
    name: 'Singapore',
    label: 'Singapore',
    id: 127215,
    value: 127215,
    CountryCode: 'SG',
  },
  {
    name: 'Ho Chi Minh City',
    label: 'Ho Chi Minh City',
    id: 132294,
    value: 132294,
    CountryCode: 'VN',
  },
  {
    name: 'Bangkok',
    label: 'Bangkok',
    id: 57954,
    value: 57954,
    CountryCode: 'TH',
  },
  {
    name: 'Taipei',
    label: 'Taipei',
    id: 116450,
    value: 116450,
    CountryCode: 'TW',
  },
];

export const COUNTRIES_WITH_TALENT_HUB_SERVICE = [CountryCodes.ID];

export const DEFAULT_COMPANY_LOGO = 'default-company-logo.png';

export { Status as APPLICATION_STATUS } from 'src/global/models/Application';

export const DATA_TYPE = {
  career: 'career',
  skill: 'skill',
  city: 'city',
  country: 'country',
  jobType: 'jobType',
  company: 'company',
  keyword: 'keyword',
  remote: 'remote',
  experience: 'experience',
  salaryFilter: 'salaryFilter',
  minSalary: 'minSalary',
  maxSalary: 'maxSalary',
  currency: 'currency',
};

export const JOB_TYPES_DATA = {
  internship: {
    value: JobType.INTERNSHIP,
    checked: true,
    dataType: DATA_TYPE.jobType,
    message: JobTypeMessages[JobType.INTERNSHIP],
  },
  fullTime: {
    value: JobType.FULL_TIME,
    checked: true,
    dataType: DATA_TYPE.jobType,
    message: JobTypeMessages[JobType.FULL_TIME],
  },
  partTime: {
    value: JobType.PART_TIME,
    checked: true,
    dataType: DATA_TYPE.jobType,
    message: JobTypeMessages[JobType.PART_TIME],
  },
  freelance: {
    value: JobType.PROJECT_BASED,
    checked: true,
    dataType: DATA_TYPE.jobType,
    message: JobTypeMessages[JobType.PROJECT_BASED],
  },
  daily: {
    value: JobType.DAILY,
    checked: true,
    dataType: DATA_TYPE.jobType,
    message: JobTypeMessages[JobType.DAILY],
  },
};

export const JOB_TYPES_LIST = [
  JOB_TYPES_DATA.internship,
  JOB_TYPES_DATA.fullTime,
  JOB_TYPES_DATA.partTime,
  JOB_TYPES_DATA.freelance,
];

export const DEFAULT_JOB_TYPES = JOB_TYPES_LIST.map((jobType) => jobType.value);

export const DEFAULT_CURRENCY = 'USD';

export const CURRENCY_CODE_BY_COUNTRY = {
  [CountryCodes.ID]: 'IDR',
  [CountryCodes.MY]: 'MYR',
  [CountryCodes.SG]: 'SGD',
  [CountryCodes.VN]: 'VND',
  [CountryCodes.TW]: 'TWD',
  DEFAULT: DEFAULT_CURRENCY,
};

// We limit max salary input to 9 digits because salaryExpectation column is using integer(max value up to 2147483647).
export const SALARY_LIMIT_DIGITS = 9;

export const languages = [
  {
    label: <FormattedMessage id="language-en" defaultMessage="English" />,
    value: 'en',
  },
  {
    label: <FormattedMessage id="language-id" defaultMessage="Indonesian" />,
    value: 'id',
  },
  {
    label: <FormattedMessage id="language-zh-my" defaultMessage="Chinese" />,
    value: 'zh-MY',
  },
  {
    label: <FormattedMessage id="language-zh-tw" defaultMessage="Chinese" />,
    value: 'zh-TW',
  },
  {
    label: <FormattedMessage id="language-vn" defaultMessage="Vietnamese" />,
    value: 'vi',
  },
];

export const TRACKING_CLASSNAMES = {
  careerCard: 'gtm-landing-career-card',
  discoverCareer: 'gtm-discover-career',
  externalApplyUrl: 'gtm-external-apply-url',
  facebookLoginButton: 'gtm-login-facebook-button',
  linkedinLoginButton: 'gtm-linkedin-login-button',
  jobApplicationSuccessModalGoToProfileButton:
    'gtm-job-application-success-modal-go-to-profile-button',
  jobApplicationSuccessModalContinueBrowsingButton:
    'gtm-job-application-success-modal-continue-browsing-button',
  jobApplicationSuccessModalCheckbox:
    'gtm-job-application-success-modal-checkbox',
  jobCardBody: 'gtm-job-card-body',
  jobCardCompanyName: 'gtm-job-card-company-name',
  jobCardDetails: 'gtm-job-card-details',
  jobCardJobTitle: 'gtm-job-card-job-title',
  jobCardSaveButton: 'gtm-job-card-save-button',
  jobDetailApplyButton: 'gtm-job-detail-apply-button',
  jobDetailCardCompany: 'gtm-job-detail-card-company',
  jobDetailJobCardSaveButton: '.gtm-job-detail-job-card-save-button',
  jobDetailFbShare: 'gtm-job-detail-fb-share',
  jobDetailGoogleShare: 'gtm-job-detail-google-share',
  jobDetailLinkedinShare: 'gtm-job-detail-linkedin-share',
  jobDetailTwitterShare: 'gtm-job-detail-twitter-share',
  loginSubmitButton: 'gtm-login-button',
  onboardingJobExperienceButton: 'gtm-i-have-job-experience-button',
  onboardingStudentButton: 'gtm-i-am-student-button',
  reportListing: 'gtm-report-listing',
  signUpLink: 'gtm-sign-up-link',
  careerCheckbox: 'gtm-career-checkbox',
  getCareerAdviceButton: 'gtm-get-career-advice-button',
  goToCommunityPageButton: 'gtm-to-community-page-button',
  forYouPageRecommendedJobCard: 'gtm-for-you-page-recommended-job-card',
};

// For saving state in any URL
export const URL_STATE = {
  apply: 'apply',
  applySuccess: 'apply-success',
};

export const LocalStorageKeys = {
  emailVerificationRequestSentAt: 'emailVerificationRequestSentAt',
  isLoginPopUpShown: 'isLoginPopUpShown',
  pastJobSearchConditionsInExploreTab: 'pastJobSearchConditionsInExploreTab',
};

export const listOfSocialNetworkName = {
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  Instagram: 'Instagram',
  LinkedIn: 'LinkedIn',
  Behance: 'Behance',
  GitHub: 'GitHub',
  Tiktok: 'Tiktok',
  CodePen: 'CodePen',
  Vimeo: 'Vimeo',
  Youtube: 'Youtube',
};

export const TALENT_HUNT_GROUP_ID = '552fce49-8b19-4934-b01b-fbc7c3ad44eb';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
export const EUserRole = {
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const MAX_NUM_OF_SKILLS = 10;
export const MIN_NUM_OF_SKILLS = 3;

export const TERMS_AND_CONDITIONS = {
  tw: 'terms-and-conditions-tw.pdf',
};

export const COUNTRY_DOMAINS = {
  default: 'default',
  hk: 'hk',
  id: 'id',
  my: 'my',
  ph: 'ph',
  sg: 'sg',
  th: 'th',
  tw: 'tw',
  vn: 'vn',
};

export const LANGUAGES = {
  en: 'en',
  id: 'id',
  vi: 'vi',
};

export const COUNTRY_CALLING_CODES = {
  [COUNTRY_DOMAINS.my]: '+60', // Malaysia
  [COUNTRY_DOMAINS.id]: '+62', // Indonesia
  [COUNTRY_DOMAINS.ph]: '+63', // Philippines
  [COUNTRY_DOMAINS.sg]: '+65', // Singapore
  [COUNTRY_DOMAINS.th]: '+66', // Thailand
  [COUNTRY_DOMAINS.vn]: '+84', // Vietname
  [COUNTRY_DOMAINS.hk]: '+852', // Hong Kong
  [COUNTRY_DOMAINS.tw]: '+886', // Taiwan
};

export const DOWNLOAD_MOBILE_APP_DYNAMIC_LINK =
  'https://dynamic-link.glints.com/page/AYij';

/** To config Job Detail Mobile App Dynamic Link following these configuration:
 * JOB_DETAIL_MOBILE_APP_DYNAMIC_LINK
 * jobId
 * MOBILE_APP_CONFIG_DYNAMIC_LINK
 */
export const JOB_DETAIL_MOBILE_APP_DYNAMIC_LINK =
  'https://dynamic-link.glints.com/page/?link=https://glints.com?inapp%3D1%26intent%3Dcatexp%26job%3D';
export const MOBILE_APP_CONFIG_DYNAMIC_LINK =
  '&apn=com.glints.candidate&isi=1613169954&ibi=com.glints.candidate';

export const UTM_CAMPAIGN = 'utm_campaign';
export const UTM_MEDIUM = 'utm_medium';
export const SHARE_ID = 'shareId';

export const STEPS_TO_HIDE_SKIP_BUTTON = [
  StepNames.JobInterest,
  StepNames.JobPreferences,
];

export const FYP_PATH = '/opportunities/jobs/recommended';
